import { defineStore } from "pinia";
import { i18n } from "@/i18n";
import { usePermissionsStore } from "./permissions";
import axios from "../axios/requestAxios";
import { sizes } from "../utils/media-query";

function getTranslation(key) {
  return i18n.global.messages.value.ru.Nav[key];
}

export const useSideMenuStore = defineStore("sideMenu", {
  state: () => {
    return {
      isLargeScreen: sizes()["screen-large"],
      rerenderKey: 0,
      countUnReaded: "",
      trigger: false,
      nav: [
        {
          text: getTranslation("Dasboard"),
          icon: "folder",
          items: [],
        },
        {
          text: getTranslation("Clients"),
          path: "/clients",
          permissionKey: "Клиенты",
          icon: "user",
        },
        {
          text: getTranslation("RentAuto"),
          icon: "folder",
          items: [],
        },
        {
          text: getTranslation("Transactions"),
          icon: "folder",
          items: [],
        },
        {
          text: getTranslation("WorkCompletionCertificate"),
          path: "/work-completion-certificate",
          icon: "file",
          permissionKey: "АВР",
        },
        {
          text: getTranslation("News"),
          icon: "info",
          path: "/all-tabs",
          permissionKey: "Новости",
        },
        {
          text: getTranslation("Branding"),
          icon: "folder",
          items: [],
        },
        {
          text: getTranslation("Notifications"),
          icon: "message",
          path: "/messages",
          permissionKey: "Рассылки для менеджера",
        },
        {
          text: getTranslation("Account"),
          icon: "folder",
          items: [],
        },
        {
          text: getTranslation("Settings"),
          icon: "folder",
          items: [],
        },

        {
          text: getTranslation("AccessControl"),
          icon: "folder",
          items: [],
        },
        {
          text: getTranslation("Catalogues"),
          icon: "folder",
          items: [],
        },
        {
          text: getTranslation("Logs"),
          path: "/logs",
          icon: "clock",
          permissionKey: "Изменения",
        },
      ],
      items: [
        {
          text: getTranslation("DasboardBranding"),
          path: "/branding-dashboard",
          permissionKey: "Дашборд(Брендинг)",
          isDashboards: true,
        },
        {
          text: getTranslation("DasboardCar"),
          path: "/car-dashboard",
          permissionKey: "Дашборд(Авто)",
          isDashboards: true,
        },
        {
          text: getTranslation("Users"),
          path: "/employees",
          permissionKey: "Сотрудники",
          accessControl: true,
        },
        {
          text: getTranslation("PhoneNumbers"),
          path: "/phones",
          permissionKey: "Телефон менеджера",
          isCatalogue: true,
        },
        {
          text: getTranslation("Comission"),
          path: "/comissions",
          permissionKey: "Комиссия",
          settings: true,
        },
        {
          text: getTranslation("Roles"),
          path: "/roles",
          permissionKey: "Роли",
          accessControl: true,
        },
        {
          text: getTranslation("Brand"),
          path: "/brands",
          permissionKey: "Марка авто",
          isCatalogue: true,
        },
        {
          text: getTranslation("Colores"),
          path: "/car-colores",
          permissionKey: "Цвет авто",
          isCatalogue: true,
        },
        {
          text: getTranslation("RejectionReasons"),
          path: "/rejection-reasons",
          permissionKey: "Причины отклонения",
          isCatalogue: true,
        },
        {
          text: getTranslation("Cities"),
          path: "/cities",
          permissionKey: "Город",
          isCatalogue: true,
        },
        {
          text: getTranslation("TaxiDepot"),
          path: "taxi-depots",
          permissionKey: "Таксопарк",
          settings: true,
        },
        {
          text: getTranslation("AccountRent"),
          path: "/rents-applications",
          permissionKey: "Заявка на аренду",
          isAccountRent: true,
        },
        {
          text: getTranslation("PrivateRent"),
          path: "/private-rent-apps",
          permissionKey: "Заявка на частные авто",
          isAccountRent: true,
        },
        {
          text: getTranslation("Cars"),
          path: "/rent-cars",
          permissionKey: "Арендный авто",
          isAutoRent: true,
        },
        {
          text: getTranslation("Branding"),
          path: "/brandings",
          permissionKey: "Брендинг",
          isBranding: true,
        },
        {
          text: getTranslation("PhotoReport"),
          path: "/photo-reports",
          permissionKey: "Фотоотчет",
          isBranding: true,
        },
        {
          text: getTranslation("Transaction"),
          path: "/transactions",
          permissionKey: "Транзакции",
          isTransaction: true,
        },
        {
          text: getTranslation("Limit"),
          path: "/limit-phones",
          permissionKey: "Ограничения",
          isTransaction: true,
        },
      ],
    };
  },
  actions: {
    rerender() {
      this.rerenderKey++;
    },
    updateItemProperties() {
      this.nav = this.nav.map((item) => {
        // переопределяем массив объектов
        if (item.path && !/^\//.test(item.path)) {
          item.path = `/${item.path}`;
        }

        return { ...item, expanded: this.isLargeScreen };
      });
    },
    setItems() {
      let catalog = [];
      let rentAuto = [];
      let accountRent = [];
      let branding = [];
      let settings = [];
      let navMenu = [...this.nav];
      let accessControl = [];
      let dashboards = [];
      let transaction = [];

      const permissionsStore = usePermissionsStore();

      navMenu.forEach((navItem) => {
        if (permissionsStore.check(navItem.permissionKey, "navigate")) {
          navItem.flag = true;
        }
      });

      this.items.forEach((item) => {
        if (!permissionsStore.check(item.permissionKey, "navigate")) return;

        if (
          item.accessControl &&
          rentAuto.indexOf((x) => x.path == item.path) == -1
        ) {
          accessControl.push(item);
          return;
        }

        if (
          item.settings &&
          rentAuto.indexOf((x) => x.path == item.path) == -1
        ) {
          settings.push(item);
          return;
        }

        if (
          item.isAutoRent &&
          rentAuto.indexOf((x) => x.path == item.path) == -1
        ) {
          rentAuto.push(item);
          return;
        }

        if (
          item.isCatalogue &&
          catalog.indexOf((x) => x.path == item.path) == -1
        ) {
          catalog.push(item);
          return;
        }

        if (
          item.isAccountRent &&
          catalog.indexOf((x) => x.path == item.path) == -1
        ) {
          accountRent.push(item);
          return;
        }

        if (
          item.isBranding &&
          catalog.indexOf((x) => x.path == item.path) == -1
        ) {
          branding.push(item);
          return;
        }

        if (
          item.isDashboards &&
          catalog.indexOf((x) => x.path == item.path) == -1
        ) {
          dashboards.push(item);
          return;
        }

        if (
          item.isTransaction &&
          transaction.indexOf((x) => x.path == item.path) == -1
        ) {
          transaction.push(item);
          return;
        }

        if (
          !item.isCatalogue &&
          !item.isAutoRent &&
          this.nav.indexOf((x) => x.path == item.path) == -1
        ) {
          this.nav.push(item);
          return;
        }
      });
      // --------------------------------------------------------

      navMenu.forEach((navItem) => {
        if (
          navItem.text === getTranslation("AccessControl") &&
          accessControl.length !== 0
        ) {
          navItem.items = accessControl;
          navItem.flag = true;
        }

        if (
          navItem.text === getTranslation("Settings") &&
          settings.length !== 0
        ) {
          navItem.items = settings;
          navItem.flag = true;
        }

        if (
          navItem.text === getTranslation("Catalogues") &&
          catalog.length !== 0
        ) {
          navItem.items = catalog;
          navItem.flag = true;
        }

        if (
          navItem.text === getTranslation("RentAuto") &&
          rentAuto.length !== 0
        ) {
          navItem.items = rentAuto;
          navItem.flag = true;
        }

        if (
          navItem.text === getTranslation("Account") &&
          accountRent.length !== 0
        ) {
          navItem.items = accountRent;
          navItem.flag = true;
        }

        if (
          navItem.text === getTranslation("Branding") &&
          branding.length !== 0
        ) {
          navItem.items = branding;
          navItem.flag = true;
        }

        if (
          navItem.text === getTranslation("Dasboard") &&
          dashboards.length !== 0
        ) {
          navItem.items = dashboards;
          navItem.flag = true;
        }

        if (
          navItem.text === getTranslation("Transactions") &&
          transaction.length !== 0
        ) {
          navItem.items = transaction;
          navItem.flag = true;
        }
      });

      navMenu = navMenu.filter((navItem) => {
        return navItem.flag;
      });

      this.nav = [...navMenu];

      this.rerender();
    },
    reset() {
      this.nav = [
        {
          text: getTranslation("Home"),
          path: "/home",
          icon: "home",
        },
        {
          text: getTranslation("Catalogues"),
          icon: "folder",
          items: [],
        },
        {
          text: getTranslation("RentAuto"),
          icon: "folder",
          items: [],
        },
      ];
      this.rerenderKey = 0;
    },
    async getCountNotify() {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/ManagerNotification/Unreaded")
          .then(async (response) => {
            this.countUnReaded = response.data;
            this.setUnReadCount(this.countUnReaded);

            resolve(response);
          })
          .catch((error) => {
            this.countUnReaded = "";
            reject(error.response);
          });
      });
    },
    setUnReadCount(count) {
      const notificationText = getTranslation("Notifications");
      this.nav.forEach((e) => {
        if (e.text.includes(notificationText)) {
          e.text = count ? `${notificationText} ${count}` : notificationText;
          this.trigger = !!count;
          this.rerenderKey++;
        }
      });
    },
  },
});
